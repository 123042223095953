import { GetSelectLookup } from "./common";

const GradeEnum = {
  Stage1: 1,
  Stage2: 2,
  Stage3: 3,
  Stage4: 4,
  Unstageable: 5
};

const GradeLookup = [
  "",
  "Stage 1: Non-blanchable erythema of intact skin",
  "Stage 2: Partial-thickness skin loss with exposed dermis",
  "Stage 3: Full-thickness skin loss",
  "Stage 4: Full-thickness skin and tissue loss",
  "Unstageable"
];

export default {
  Enum: { Current: 0, Deleted: 1, Healed: 2, All: 3 },
  Lookup: ["Current", "Deleted", "Healed", "All"],
  HealingFactorType: {
    Localised: 0,
    Systemic: 1
  },
  GradeEnum: GradeEnum,
  GradeSelectLookup: GetSelectLookup(GradeEnum, GradeLookup),
  Ulcers: [316] // 299, 307, 308, 309, 315,
};
