<template>
  <CollapseCard :title="title" :name="name" :open.sync="attachmentsOpen" :showAdd="!readonly" @add="addAttachment" class="primaryCard">
    <b-carousel v-model="selectedId" :autoplay="false" :indicator-inside="false">
      <b-carousel-item v-for="(attachment, i) in attachments" :key="i">
        <img class="clickable" :src="attachment.fileUrl" @click.stop="editAttachment" />
      </b-carousel-item>
    </b-carousel>

    <div v-if="!!selectedAttachment">
      <b-field label="Name" horizontal>
        {{ selectedAttachment.name }}
        <RecordedBy label="Taken" :date="selectedAttachment.modified" :by="selectedAttachment.who" />
      </b-field>
      <b-field v-if="selectedAttachment.notes" label="Notes" horizontal>
        {{ selectedAttachment.notes }}
      </b-field>
    </div>
  </CollapseCard>
</template>

<script>
import AttachmentDialog from "@/components/dialogs/AttachmentDialog.vue";
import CollapseCard from "@/components/CollapseCard";
import RecordedBy from "@/components/RecordedBy.vue";

import Dates from "@/common/dates";
import { mapGetters } from "vuex";
import saveIfNew from "@/mixins/saveIfNewMixin.js";

export default {
  mixins: [saveIfNew],
  components: { CollapseCard, RecordedBy },
  props: {
    entityId: { validator: prop => typeof prop === "number" || prop === null || prop === undefined, required: false, default: null },
    attachmentType: { type: Number, required: true },
    readonly: { type: Boolean },
    title: { type: String, default: "Attachments" },
    name: { type: String, default: "Attachment" }
  },
  data() {
    return {
      isLoadingAttachments: false,
      attachmentsOpen: false,
      attachments: [],
      selectedId: 0
    };
  },
  watch: {
    attachmentsOpen(open) {
      if (open && !this.attachments.length) this.refreshAttachments();
    }
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    selectedAttachment() {
      return this.selectedId !== null ? this.attachments[this.selectedId] : null;
    }
  },
  methods: {
    shortFormat(date) {
      return Dates.shortFormat(date);
    },
    refreshAttachments() {
      if (!this.entityId) return;
      this.isLoadingAttachments = true;
      this.$http
        .get("attachments/" + this.entityId + "?attachmentType=" + this.attachmentType)
        .then(r => {
          this.attachments = r.data;
          if (this.attachments.length) {
            this.attachmentsOpen = true; // open initially if we have any
            this.$nextTick(() => (this.selectedId = this.attachments.length - 1)); // default to last image
          }
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Attachments"))
        .finally(() => (this.isLoadingAttachments = false));
    },
    addAttachment() {
      this.saveIfNew(() => this.entityId, this.title).then(() => {
        this.selectedId = null;
        this.editAttachment();
      });
    },
    editAttachment(e) {
      // had issue with click on carousel image firing twice, so we ignore the non trusted clicked
      if (e?.isTrusted === false) return;

      this.$buefy.modal.open({
        parent: this,
        component: AttachmentDialog,
        hasModalCard: true,
        props: { attachment: this.selectedAttachment, entityId: this.entityId, attachmentType: this.attachmentType },
        events: { refresh: this.refreshAttachments }
      });
    },
    deletedAttachment(id) {
      this.attachments = this.attachments.filter(r => r.attachmentId !== id);
    }
  },
  mounted() {
    this.$nextTick(this.refreshAttachments);
  }
};
</script>

<style scoped>
/deep/ .carousel-items {
  justify-content: center;
  width: inherit !important;
}
.carousel-item {
  width: inherit !important;
}
.carousel-item img {
  max-height: 500px;
}
</style>
