export default {
  methods: {
    // getEntityId is a function to return the current EntityId
    saveIfNew(getEntityId, entityName) {
      if (!getEntityId()) {
        this.$root.$emit("saveFirst");
        // saveFirst will attempt to save the dialog, so we wait 1 second and then try agin
        return new Promise(resolve => {
          setTimeout(() => {
            if (getEntityId()) {
              // do the add now that we have saved the parent
              resolve();
            } else {
              this.$buefy.snackbar.open({ message: "Please save record first before adding " + entityName });
            }
          }, 1000);
        });
      } else {
        return Promise.resolve();
      }
    }
  }
};
