<template>
  <!-- Selection option based on the lookup id -->
  <b-select
    :value="value"
    @input="(v) => $emit('input', v)"
    :class="{ smallWidth: smallWidth }"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
  >
    <template v-if="withCategories">
      <optgroup v-for="group in dataArray" :label="group.category" :key="group.category">
        <option v-for="option in group.lookups" :value="option.lookupId" :key="option.lookupId">
          {{ option.name }}
        </option>
      </optgroup>
    </template>
    <option v-else v-for="option in dataArray" :value="option.lookupId" :key="option.lookupId">
      {{ option.name }}
    </option>
  </b-select>
</template>

<script>
import lookups from "@/enums/lookups.js";
export default {
  Enum: lookups.Enum,
  props: {
    value: { validator: (prop) => typeof prop === "number" || prop === null || prop === undefined, required: true },
    lookupType: { type: Number, required: true },
    parentId: { validator: (prop) => typeof prop === "number" || prop === null || prop === undefined, required: false, default: null },
    noChildren: { type: Boolean }, // inform secondary not to try getting data again
    secondary: { type: Boolean },
    withCategories: { type: Boolean },
    smallWidth: { type: Boolean },
    placeholder: { type: String, required: false },
    required: { type: Boolean },
    disabled: { type: Boolean }
  },
  data() {
    return {
      fetchingData: false,
      name: this.value
    };
  },
  computed: {
    lookupInfo() {
      return {
        lookupType: this.lookupType,
        parentId: this.parentId,
        withCategories: this.withCategories
      };
    },
    dataArray() {
      if (this.secondary) {
        if (!this.parentId) return [];

        // check if we've already tried to get children (noChildren value will be set)
        const lookup = this.$store.getters["lookups/getLookup"](this.parentId);
        if (lookup?.noChildren) return [];
      }

      var values = this.$store.getters["lookups/getLookups"](this.lookupInfo);
      if ((!values || !values.length) && !this.fetchingData) {
        this.getData();
      }
      return values;
    }
  },
  methods: {
    async getData() {
      this.fetchingData = true;
      await this.$store.dispatch("lookups/getLookups", this.lookupInfo);
      this.fetchingData = false;
    }
  }
};
</script>
