<template>
  <div>
    <div id="dragWrapper">
      <div
        id="drag"
        style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
      ></div>
    </div>
    <div class="bodymap_parent_container" :ref="imageId + 'pc'" :id="imageId + 'pc'">
      <!--Parent Container gets resize based on columns, then image gets set to same size (width) -->
      <!-- Found refresh of image didn't happen immediately, so added hidden field to force repaint -->
      <!-- <p class="hiddenField">{{ imageSize.width }}</p> -->
      <div
        class="bodymap_container"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        @mousemove="mousemove"
        :style="{
          maxWidth: imageSize.maxWidth + 'px',
          height: imageSize.height + 'px'
        }"
      >
        <img :src="bodyMap" alt="body map" draggable="false" :usemap="'#' + mapId" :id="imageId" />
        <template v-for="wound in wounds">
          <img
            :key="wound.woundId"
            class="bodymap_dot"
            :src="locationDot(wound)"
            draggable="false"
            :style="{
              left: wound.posX * scaleFactor.width * zoomScale - orginOffset.x - dotRadius + 'px',
              top: wound.posY * scaleFactor.height * zoomScale - orginOffset.y - dotRadius + 'px'
            }"
            @click.stop="woundSelect(wound)"
          />
        </template>

        <map :name="mapId" :id="mapId">
          <area
            v-for="location in woundLocations"
            :key="location.lookupId"
            :alt="location.lookupId"
            :title="location.name"
            href=""
            :coords="location.data"
            :shape="location.data.length > 16 ? 'polygon' : location.data.length < 11 ? 'circle' : 'rect'"
            @click.prevent.stop="addNewWound"
          />
        </map>
      </div>
    </div>
  </div>
</template>

<script>
import lookups from "@/enums/lookups.js";
import GenderEnum from "@/enums/gender.js";
import woundStatus from "@/enums/woundStatus";
import Hammer from "hammerjs";

export default {
  props: {
    wounds: { type: Array, required: true },
    id: { type: String, required: true },
    selectedWound: { type: Object },
    gender: { type: Number },
    inDialog: { type: Boolean },
    zoomOnHover: { type: Boolean, default: true }
  },
  data() {
    const imageDefaultSize = 500;
    return {
      scaleFactor: { width: 1, heigh: 1 },
      zoomScale: 1,
      lastScale: 1, // used in pinch zoom
      orginOffset: { x: 0, y: 0 },
      imageSize: { width: imageDefaultSize, height: imageDefaultSize },
      dotRadius: 6,
      imageDefaultSize: imageDefaultSize,

      // map resize variables
      timer: null,
      padding: null,
      areas: null,
      cachedAreaCoordsArray: null,
      image: null,
      map: null,
      isWidth: null,
      initDone: false,

      zoomInfo: {
        element: undefined,
        fixHammerjsDeltaIssue: undefined,
        pinchStart: { x: undefined, y: undefined },
        lastEvent: undefined,
        originalSize: {
          width: imageDefaultSize,
          height: imageDefaultSize
        },
        current: {
          x: 0,
          y: 0,
          z: 1, // scale
          width: imageDefaultSize * 1,
          height: imageDefaultSize * 1
        },
        last: {
          x: 0,
          y: 0,
          z: 1
        },
        pinchZoomOrigin: undefined
      }
    };
  },
  computed: {
    imageId() {
      return this.id + "image";
    },
    mapId() {
      return this.id + "map";
    },
    woundLocations() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: lookups.Enum.WoundLocation, gender: this.gender });
      if (!values || !values.length) {
        this.$store.dispatch("lookups/getLookups", { lookupType: lookups.Enum.WoundLocation });
      }
      return values;
    },
    bodyMap() {
      return this.gender === GenderEnum.Enum.Male ? require("@/assets/male_3d.png") : require("@/assets/female_3d.png");
    }
  },
  watch: {
    woundLocations: {
      immediate: true,
      handler(n, o) {
        // initialise image map resize only after locations loaded
        if (!o?.length && n.length > 0) this.$nextTick(this.scaleImageMapInit);
      }
    }
  },
  methods: {
    findPosition(oElement) {
      if (typeof oElement.offsetParent !== "undefined") {
        for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
          posX += oElement.offsetLeft;
          posY += oElement.offsetTop;
        }
        return [posX, posY];
      } else {
        return [oElement.x, oElement.y];
      }
    },
    getCoordinates(e) {
      var posX = 0;
      var posY = 0;
      var ImgPos;
      var myImg = document.getElementById(this.imageId);

      ImgPos = this.findPosition(myImg);
      // console.log("ImgPos", ImgPos);

      if (!e) e = window.event;
      if (e.pageX || e.pageY) {
        posX = e.pageX;
        posY = e.pageY;
      } else if (e.clientX || e.clientY) {
        posX = e.clientX + document.body.scrollLeft;
        posY = e.clientY + document.body.scrollTop;
      }

      // Page Scroll does not affect our "pages", but when in dialog it needs to be added - need to set inDialog prop
      const scroll = { scrollLeft: 0, scrollTop: 0 };
      if (this.inDialog) {
        scroll.scrollLeft = document.documentElement.scrollLeft;
        scroll.scrollTop = document.documentElement.scrollTop;

        // Get modal scoll of last dialog
        const modals = document.querySelectorAll(".modal-card-body");
        const modalScroll = modals.length ? modals[modals.length - 1] : null;
        if (modalScroll) {
          scroll.scrollLeft -= modalScroll.scrollLeft;
          scroll.scrollTop -= modalScroll.scrollTop;
          // console.log("modalScroll", modals.length, modalScroll.scrollLeft, modalScroll.scrollTop);
        }
      }

      // console.log("getCoordinates", posX, ImgPos, posX - ImgPos[0], posY - ImgPos[1], posY, scroll.scrollTop, scroll.scrollTop);

      posX = posX - ImgPos[0] - scroll.scrollLeft;
      posY = posY - ImgPos[1] - scroll.scrollTop;
      // console.log("before scale", posX, posY);

      // scale pos back to full size
      posX = parseInt(posX / this.scaleFactor.width);
      posY = parseInt(posY / this.scaleFactor.height);
      // console.log("after scale", posX, posY);

      return { x: posX, y: posY };
    },
    addNewWound(e) {
      const newPos = this.getCoordinates(e);
      this.$emit("newdot", { ...newPos, locationId: parseInt(e.target.alt) });
    },
    woundSelect(wound) {
      this.$emit("selectWound", wound.woundId);
    },
    locationDot(wound) {
      // prettier-ignore
      return wound === this.selectedWound
        ? require("@/assets/dot_selected.png")
        : wound.status === woundStatus.Enum.Current
          ? require("@/assets/dot.png")
          : require("@/assets/dot_green.png");
    },

    scale(coord) {
      this.isWidth = 1 - this.isWidth;
      var dimension = this.isWidth === 1 ? "width" : "height";
      return this.padding[dimension] + Math.floor(Number(coord) * this.scaleFactor[dimension]);
    },

    resizeAreaTag(cachedAreaCoords, idx) {
      this.isWidth = 0;
      if (this.areas[idx] === undefined) return;

      this.areas[idx].coords = cachedAreaCoords.split(",").map(this.scale).join(",");
    },

    resizeMap() {
      this.scaleFactor = {
        width: this.image.width / this.image.naturalWidth,
        height: this.image.height / this.image.naturalHeight
      };

      this.padding = {
        width: parseInt(window.getComputedStyle(this.image, null).getPropertyValue("padding-left"), 10),
        height: parseInt(window.getComputedStyle(this.image, null).getPropertyValue("padding-top"), 10)
      };
      this.imageMapResized();

      // console.log("before", this.areas[0]?.title, this.areas[0]?.coords, this.scaleFactor);
      this.cachedAreaCoordsArray.forEach(this.resizeAreaTag);
      // console.log("after", this.areas[0]?.title, this.areas[0]?.coords);
    },

    getCoords(e) {
      // Normalize coord-string to csv format without any space chars
      return e.coords.replace(/ *, */g, ",").replace(/ +/g, ",");
    },

    debounce() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.windowResized, 250);
    },

    start() {
      if (this.image.width !== this.image.naturalWidth || this.image.height !== this.image.naturalHeight) {
        this.windowResized();
      }
    },

    addEventListeners() {
      this.image.addEventListener("load", this.windowResized, false); // Detect late image loads in IE11
      window.addEventListener("focus", this.windowResized, false); // Cope with window being resized whilst on another tab
      window.addEventListener("resize", this.debounce, false);
      window.addEventListener("readystatechange", this.windowResized, false);
      document.addEventListener("fullscreenchange", this.windowResized, false);
    },

    setup() {
      this.map = document.getElementById(this.mapId);
      this.areas = this.map.getElementsByTagName("area");
      this.cachedAreaCoordsArray = Array.prototype.map.call(this.areas, this.getCoords);
      this.image = document.getElementById(this.imageId);
    },

    scaleImageMapInit() {
      this.areas = null;
      this.cachedAreaCoordsArray = null;

      if (!this.initDone) {
        this.initDone = true;
        this.setup();
        this.addEventListeners();
        this.start();
      } else {
        this.windowResized();
      }
    },

    imageMapResized() {
      // console.log("imageMapResized", this.scaleFactor.width, this.scaleFactor.height, this.imageSize.width);
    },
    mouseover(e) {
      if (this.zoomOnHover) {
        this.zoomImage(3);
        this.mousemove(e); // First time wound dialog displayed, we have a mouse over without the mousemove, so we call it manually
      }
    },
    mouseleave() {
      if (this.zoomOnHover) {
        this.zoomImage(1);
        this.orginOffset.x = 0;
        this.orginOffset.y = 0;
      }
    },
    zoomImage(scale) {
      this.zoomScale = scale;
      this.addImgCss({ transform: "scale(" + scale + ")" });
    },
    mousemove(e) {
      if (this.zoomOnHover) {
        var pos = this.getCoordinates(e);

        const orginOffsetPercentX = (pos.x / e.currentTarget.offsetWidth) * this.scaleFactor.width;
        const orginOffsetPercentY = (pos.y / e.currentTarget.offsetHeight) * this.scaleFactor.height;

        this.orginOffset.x = orginOffsetPercentX * this.imageSize.width * (this.zoomScale - 1);
        this.orginOffset.y = orginOffsetPercentY * this.imageSize.height * (this.zoomScale - 1);

        // console.log("x", pos.x, e.currentTarget.offsetWidth, this.scaleFactor.width, orginOffsetPercentX);
        // console.log("y", pos.y, e.currentTarget.offsetHeight, this.scaleFactor.height, orginOffsetPercentY);

        this.addImgCss({
          "transform-origin": orginOffsetPercentX * 100 + "% " + orginOffsetPercentY * 100 + "%"
        });
      }
    },
    panMove(ev) {
      var e = ev.changedPointers[0];
      var pos = this.getCoordinates(e);
      console.log(pos, e);

      const orginOffsetPercentX = (pos.x / e.currentTarget.offsetWidth) * this.scaleFactor.width;
      const orginOffsetPercentY = (pos.y / e.currentTarget.offsetHeight) * this.scaleFactor.height;

      this.orginOffset.x = orginOffsetPercentX * this.imageSize.width * (this.zoomScale - 1);
      this.orginOffset.y = orginOffsetPercentY * this.imageSize.height * (this.zoomScale - 1);

      // console.log("x", pos.x, e.currentTarget.offsetWidth, this.scaleFactor.width, orginOffsetPercentX);
      // console.log("y", pos.y, e.currentTarget.offsetHeight, this.scaleFactor.height, orginOffsetPercentY);

      this.addImgCss({
        "transform-origin": orginOffsetPercentX * 100 + "% " + orginOffsetPercentY * 100 + "%"
      });
      // }
    },
    addImgCss(styles) {
      this.addCss(document.getElementById(this.imageId), styles);
    },
    addCss(element, style) {
      for (const property in style) element.style[property] = style[property];
    },
    windowResized() {
      // resize image container based on new size on parent container
      let pc = this.$refs[this.imageId + "pc"];
      if (!pc) pc = document.getElementById(this.imageId + "pc");

      if (!pc) {
        this.debounce();
        return;
      }
      this.imageSize.maxWidth = pc.clientWidth;
      this.imageSize.maxHeight = pc.clientHeight;
      // Explicitly set the image width to be the same as the container, this is needed for the image map resize
      this.imageSize.width = pc.clientWidth;
      // as we have square image we set height to be the same as width
      this.imageSize.height = this.imageSize.width;

      // first time the img has not been resized, so we wait short while
      setTimeout(this.resizeMap, 50);
    },
    // Hammer pinch function
    getRelativePosition(element, point, originalSize, scale) {
      var domCoords = this.hammer_getCoords(element);

      var elementX = point.x - domCoords.x;
      var elementY = point.y - domCoords.y;

      var relativeX = elementX / ((originalSize.width * scale) / 2) - 1;
      var relativeY = elementY / ((originalSize.height * scale) / 2) - 1;
      return { x: relativeX, y: relativeY };
    },
    hammer_getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { x: Math.round(left), y: Math.round(top) };
    },
    scaleFrom(zoomOrigin, currentScale, newScale) {
      var currentShift = this.getCoordinateShiftDueToScale(this.zoomInfo.originalSize, currentScale);
      var newShift = this.getCoordinateShiftDueToScale(this.zoomInfo.originalSize, newScale);

      var zoomDistance = newScale - currentScale;

      var shift = {
        x: currentShift.x - newShift.x,
        y: currentShift.y - newShift.y
      };

      var output = {
        x: zoomOrigin.x * shift.x,
        y: zoomOrigin.y * shift.y,
        z: zoomDistance
      };
      return output;
    },

    getCoordinateShiftDueToScale(size, scale) {
      var newWidth = scale * size.width;
      var newHeight = scale * size.height;
      var dx = (newWidth - size.width) / 2;
      var dy = (newHeight - size.height) / 2;
      return {
        x: dx,
        y: dy
      };
    },
    checkFit() {
      const maxPosX = Math.ceil((this.zoomInfo.current.width - this.zoomInfo.originalSize.width) / 2);
      const maxPosY = Math.ceil((this.zoomInfo.current.height - this.zoomInfo.originalSize.height) / 2);

      var doUpdate = false;
      if (this.zoomInfo.current.x > maxPosX) {
        this.zoomInfo.current.x = maxPosX;
        doUpdate = true;
      }
      if (this.zoomInfo.current.x < -maxPosX) {
        this.zoomInfo.current.x = -maxPosX;
        doUpdate = true;
      }
      if (this.zoomInfo.current.y > maxPosY) {
        this.zoomInfo.current.y = maxPosY;
        doUpdate = true;
      }
      if (this.zoomInfo.current.y < -maxPosY) {
        this.zoomInfo.current.y = -maxPosY;
        doUpdate = true;
      }
      if (doUpdate) {
        this.update();
      }
    },
    update() {
      this.zoomInfo.current.height = this.zoomInfo.originalSize.height * this.zoomInfo.current.z;
      this.zoomInfo.current.width = this.zoomInfo.originalSize.width * this.zoomInfo.current.z;
      this.zoomInfo.element.style.transform =
        "translate3d(" + this.zoomInfo.current.x + "px, " + this.zoomInfo.current.y + "px, 0) scale(" + this.zoomInfo.current.z + ")";
    },
    // Hammer event handlers
    hammer_doubletap(e) {
      var scaleFactor = 3;
      var newZ = this.zoomInfo.current.z > 1 ? 1 : scaleFactor;

      this.zoomInfo.element.style.transition = "0.3s";
      setTimeout(() => {
        this.zoomInfo.element.style.transition = "none";
      }, 300);

      var zoomOrigin = this.getRelativePosition(
        this.zoomInfo.element,
        { x: e.center.x, y: e.center.y },
        this.zoomInfo.originalSize,
        this.zoomInfo.current.z
      );
      var d = this.scaleFrom(zoomOrigin, this.zoomInfo.current.z, newZ);
      this.zoomInfo.current.x += d.x;
      this.zoomInfo.current.y += d.y;
      this.zoomInfo.current.z += d.z;

      this.zoomInfo.last.x = this.zoomInfo.current.x;
      this.zoomInfo.last.y = this.zoomInfo.current.y;
      this.zoomInfo.last.z = this.zoomInfo.current.z;

      this.update();

      this.checkFit();
    },
    hammer_pan(e) {
      if (this.zoomInfo.lastEvent !== "pan") {
        this.zoomInfo.fixHammerjsDeltaIssue = {
          x: e.deltaX,
          y: e.deltaY
        };
      }

      this.zoomInfo.current.x = this.zoomInfo.last.x + e.deltaX - this.zoomInfo.fixHammerjsDeltaIssue.x;
      this.zoomInfo.current.y = this.zoomInfo.last.y + e.deltaY - this.zoomInfo.fixHammerjsDeltaIssue.y;
      this.zoomInfo.lastEvent = "pan";
      this.update();
    },
    hammer_pinch(e) {
      var d = this.scaleFrom(this.zoomInfo.pinchZoomOrigin, this.zoomInfo.last.z, this.zoomInfo.last.z * e.scale);
      this.zoomInfo.current.x = d.x + this.zoomInfo.last.x + e.deltaX;
      this.zoomInfo.current.y = d.y + this.zoomInfo.last.y + e.deltaY;
      this.zoomInfo.current.z = d.z + this.zoomInfo.last.z;
      this.zoomInfo.lastEvent = "pinch";
      this.update();
    },
    hammer_pinchstart(e) {
      this.zoomInfo.pinchStart.x = e.center.x;
      this.zoomInfo.pinchStart.y = e.center.y;
      this.zoomInfo.pinchZoomOrigin = this.getRelativePosition(
        this.zoomInfo.element,
        { x: this.zoomInfo.pinchStart.x, y: this.zoomInfo.pinchStart.y },
        this.zoomInfo.originalSize,
        this.zoomInfo.current.z
      );
      this.zoomInfo.lastEvent = "pinchstart";
    },
    hammer_panend(e) {
      this.checkFit();
      this.zoomInfo.last.x = this.zoomInfo.current.x;
      this.zoomInfo.last.y = this.zoomInfo.current.y;
      this.zoomInfo.lastEvent = "panend";
    },
    hammer_pinchend(e) {
      // prevent zooming out beyond scale of 1
      if (this.zoomInfo.current.z < 1) {
        this.zoomInfo.current.z = 1;
        this.update();
      }

      this.zoomInfo.last.x = this.zoomInfo.current.x;
      this.zoomInfo.last.y = this.zoomInfo.current.y;
      this.zoomInfo.last.z = this.zoomInfo.current.z;
      this.zoomInfo.lastEvent = "pinchend";
    }
  },
  mounted() {
    // window.addEventListener("resize", this.windowResized);
    // this.windowResized(); // call initialtime

    // Pinch / Zoom using hammer and inspired from https://bl.ocks.org/stephanbogner/06c3e0d3a1c8fcca61b5345e1af80798
    const elm = document.querySelector("#" + this.imageId);
    var mc = new Hammer(elm, {});
    mc.get("pinch").set({ enable: true });

    this.zoomInfo.element = document.getElementById("drag");
    var hammertime = new Hammer(this.zoomInfo.element, {});

    hammertime.get("pinch").set({ enable: true });
    hammertime.get("pan").set({ threshold: 0 });

    hammertime.on("doubletap", this.hammer_doubletap);
    hammertime.on("pan", this.hammer_pan);
    hammertime.on("pinch", this.hammer_pinch);
    hammertime.on("pinchstart", this.hammer_pinchstart);
    hammertime.on("panend", this.hammer_panend);
    hammertime.on("pinchend", this.hammer_pinchend);

    hammertime.on("press", function (e) {
      console.log("press , ", e);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  }
};
</script>

<style scoped>
.bodymap_parent_container {
  height: 100%;
  width: 100%;
}
map area {
  cursor: crosshair;
}
.bodymap_container {
  position: relative;
  overflow: hidden;
  width: 500px;
  height: 500px;
}
.bodymap_container img {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-out;
}
.bodymap_dot {
  position: absolute;
  cursor: pointer;
}

#dragWrapper {
  margin: 40px;
  background: whitesmoke;
  width: 500px;
  height: 500px;
  position: relative;
  overflow: hidden;
}

#drag {
  touch-action: auto;
  height: 500px;
  width: 500px;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("http://localhost:8080/img/male_3d.59a4c962.png");
  background-size: cover;
}
</style>
