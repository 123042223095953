<template>
  <div class="mb-3">
    <template v-for="(h, i) in healingFactors">
      <b-field :key="h.hfId" :label="i === 0 ? label : ''" horizontal>
        <div class="is-flex">
          <div class="unitLabel minWidth">{{ i + 1 }}</div>
          <LookupSelectById
            :value="h.healingFactorId"
            @input="v => $emit('updateHealingFactorId', { h: h, v: v })"
            :lookupType="lookupType"
            :disabled="disabled"
            :title="label"
            class="is-flex-grow-1"
            required
            :excludeList="excludeList"
          />
          <ClickIcon icon="add" title="Add Healing Factor" @click="$emit('add')" tooltipPosition="is-left" />
          <ClickIcon icon="delete" title="Delete Healing Factor" @click="$emit('delete', h.hfId)" tooltipPosition="is-left" />
          <ClickIcon
            v-if="!(h.showNotes || h.notes)"
            icon="notes"
            title="Add Healing Factor Notes"
            @click="showNotes(h)"
            tooltipPosition="is-left"
          />
        </div>
      </b-field>
      <b-field horizontal :key="'n' + h.hfId" v-if="h.showNotes || h.notes" class="indent">
        <b-input v-model="h.notes" :disabled="disabled" placeholder="Enter reason or notes on factor delaying healing" />
      </b-field>
    </template>
    <template v-if="healingFactors.length === 0">
      <b-field :label="label" horizontal>
        <ClickIcon icon="add" :title="'Add ' + label + ' Healing Factor'" @click="$emit('add')" tooltipPosition="is-right" />
      </b-field>
    </template>
  </div>
</template>

<script>
import LookupSelectById from "@/components/LookupSelectById.vue";
import ClickIcon from "@/components/ClickIcon";

export default {
  props: {
    label: { type: String, required: true },
    lookupType: { type: Number, required: true },
    healingFactors: { type: Array },
    excludeList: { type: Array },
    disabled: { type: Boolean }
  },
  components: { LookupSelectById, ClickIcon },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    showNotes(healingFactor) {
      healingFactor.showNotes = true;
    }
  }
};
</script>
