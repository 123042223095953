<template>
  <b-field grouped :message="message">
    <b-radio v-model="newValue" :name="name" :native-value="true" required :disabled="disabled">{{ yes }}</b-radio>
    <b-radio v-model="newValue" :name="name" :native-value="false" :disabled="disabled">{{ no }}</b-radio>
    <slot />
  </b-field>
</template>

<script>
import YesNoUnsure from "@/enums/yesNoUnsure";

export default {
  name: "YesNoUnsurePicker",
  props: {
    value: { type: Boolean, default: null },
    name: { type: String, required: true },
    message: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    yes: { type: String, default: "Yes" },
    no: { type: String, default: "No" }
  },
  data() {
    return {
      newValue: null,
      yesNoUnsure: YesNoUnsure.Enum
    };
  },
  watch: {
    value(newValue) {
      this.newValue = newValue;
    },
    newValue(newValue) {
      this.$emit("input", newValue);
    }
  },
  created() {
    this.newValue = this.value;
  }
};
</script>
