<template>
  <section class="section is-main-section">
    <CardComponent title="Wounds" icon="wound" :header-icons="headerIcons" @header-icons-click="headerClicked">
      <WoundsList ref="woundList" id="wounds" />
    </CardComponent>
  </section>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import WoundsList from "@/components/Wounds/WoundsList";

import ResidentMixin from "@/mixins/residentMixin";
import DatesMixin from "@/mixins/datesMixin";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [ResidentMixin, DatesMixin, IconsMixin],
  components: { CardComponent, WoundsList },

  computed: {
    ...mapGetters(["hasPermission"]),
    headerIcons() {
      const icons = [IconsMixin.headerIconRefresh()];
      if (this.hasPermission(PermissionsEnum.WoundAdd)) {
        icons.unshift(IconsMixin.headerIconAdd("Wound"));
      }
      return icons;
    }
  },
  methods: {
    headerClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$refs.woundList.addWound();
          break;
        case "refresh":
          this.$refs.woundList.refreshWounds();
          break;
      }
    }
  }
};
</script>
