import { GetSelectLookup } from "./common";

const SkinIntegrityEnum = {
  Dry: 1,
  Flaky: 2,
  Moist: 3,
  Excoriated: 4,
  Bruising: 5,
  Scratch: 6,
  Broken: 7,
  SkinTear: 8,
  Blister: 9,
  Blanching: 10,
  NonBlanching: 11
};

const SkinIntegrityLookup = [
  "",
  "Dry",
  "Dry & flaky",
  "Moist",
  "Excoriated",
  "Bruising",
  "Scratch",
  "Broken",
  "Skin Tear",
  "Blister",
  "Redness - Blanching",
  "Redness - Non-Blanching"
];

export default {
  Enum: SkinIntegrityEnum,
  SelectLookup: GetSelectLookup(SkinIntegrityEnum, SkinIntegrityLookup),
  Lookup: SkinIntegrityLookup
};
