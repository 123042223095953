<template>
  <div>
    <XyeaFormEdit
      icon="wound"
      url="wounds/"
      entityName="Wound"
      :form="form"
      :id="form.woundId"
      @close="$emit('close')"
      @add="(v) => $emit('woundAdded', v)"
      @update="refresh"
      :disabled="woundsReadonly"
      :dialog="true"
      :loadOnOpen="false"
      :isWideDialog="true"
      @mapServerData="mapServerData"
    >
      <div class="columns">
        <div class="column is-4-desktop">
          <BodyMap
            :wounds="thisWound"
            :gender="gender"
            @newdot="updateWoundLocation"
            :selectedWound="wound"
            id="editWound"
            :inDialog="true"
          />
        </div>
        <div class="column">
          <ValidationProvider :rules="locationRules" v-slot="{ errors }">
            <b-field label="Location" horizontal :type="{ 'is-danger': errors[0] }" :message="errors" class="labelPaddingFix">
              {{ woundLocationName(form.bodyPartId) }}
              <b-input v-model="form.bodyPartId" class="hiddenField" />
            </b-field>
          </ValidationProvider>

          <b-field label="Skin Condition" horizontal>
            <div class="columns">
              <div class="column">
                <EnumDropDown v-model="form.primaryType" :data="skinIntegrity.SelectLookup" required :disabled="woundsReadonly" />
              </div>
              <div class="column">
                <b-field v-if="form.reportedDate" label="Reported" horizontal class="field-nested">
                  <p class="unitLabel">{{ formatDateTimeRecent(form.reportedDate) }}</p>
                </b-field>
              </div>
            </div>
          </b-field>

          <b-field label="Wound Type" horizontal v-if="updateAdvanced">
            <div class="columns">
              <div class="column">
                <LookupSelect
                  v-model="form.woundTypeId"
                  :lookupType="lookupWoundType"
                  required
                  :disabled="woundsReadonly"
                  placeholder="Select Wound Type"
                  :withCategories="true"
                />
              </div>
              <div class="column">
                <b-field label="Grade" horizontal v-if="showGrade">
                  <EnumDropDown v-model="form.grade" :data="woundStatusEnum.GradeSelectLookup" required :disabled="woundsReadonly" />
                </b-field>
              </div>
            </div>
          </b-field>

          <b-field label="Description" horizontal>
            <b-input v-model="form.description" :required="updateAdvanced" :disabled="woundsReadonly" />
          </b-field>
          <div v-if="updateAdvanced">
            <b-field label="Acquired" horizontal>
              <YesNoPicker v-model="form.onSite" name="acquired" :disabled="woundsReadonly" required yes="On Site" no="Off Site" />
              <b-field label="Healed" horizontal>
                <b-switch v-model="healed" type="is-success" :disabled="woundsReadonly">
                  {{ formatDateTimeRecent(form.healedDateLocal) }}
                </b-switch>
              </b-field>
            </b-field>

            <b-field label="Underlying Cause" horizontal class="onTopLabel">
              <SpeechToText v-model="form.underlyingCause" required :disabled="woundsReadonly" />
            </b-field>
            <NotesIconField
              label="Other Notes"
              type="textarea"
              notesRef="other"
              placeholder="Enter any other notes or observations"
              v-model="form.notes"
              :readonly="woundsReadonly"
            />
            <b-field label="Factors Delaying Healing" horizontal class="onTopLabel">
              <YesNoPicker
                v-model="form.anyHealingFactors"
                name="anyHealingFactors"
                :disabled="woundsReadonly"
                required
                yes="Yes"
                no="None"
              />
            </b-field>
            <b-field v-if="form.anyHealingFactors === false" label="Reason" horizontal>
              <SpeechToText
                v-model="form.noHealingFactorNotes"
                required
                :disabled="woundsReadonly"
                placeholder="Reason why no factors delaying healing"
              />
            </b-field>

            <template v-if="form.anyHealingFactors === true">
              <ValidationProvider :rules="healingFactorRules" v-slot="{ errors }">
                <b-field horizontal :type="{ 'is-danger': errors[0] }" :message="errors">
                  <b-input class="hiddenField" />
                </b-field>
              </ValidationProvider>
              <HealingFactors
                label="Localised"
                :lookupType="lookupEnum.HealingFactorLocal"
                :healingFactors="localisedHealingFactors"
                :excludeList="excludeList"
                :disabled="woundsReadonly"
                @add="addHealingFactor(woundStatusEnum.HealingFactorType.Localised)"
                @delete="deleteHealingFactor"
                @updateHealingFactorId="updateHealingFactorId"
              />
              <HealingFactors
                label="Systemic"
                :lookupType="lookupEnum.HealingFactorSystemic"
                :healingFactors="systemicHealingFactors"
                :excludeList="excludeList"
                :disabled="woundsReadonly"
                @add="addHealingFactor(woundStatusEnum.HealingFactorType.Systemic)"
                @delete="deleteHealingFactor"
                @updateHealingFactorId="updateHealingFactorId"
              />
            </template>
          </div>

          <AttachmentsCarousel
            :entityId="form.woundId"
            :attachmentType="attachmentType"
            :readonly="woundsReadonly"
            title="Images"
            name="Image"
          />
        </div>
      </div>
    </XyeaFormEdit>
  </div>
</template>

<script>
import XyeaFormEdit from "@/components/XyeaFormEdit";
import SpeechToText from "@/components/SpeechToText";
import BodyMap from "@/components/Wounds/BodyMap";
import LookupSelect from "@/components/LookupSelect.vue";
import YesNoPicker from "@/components/YesNo";
import AttachmentsCarousel from "@/components/AttachmentsCarousel.vue";
import Attachments from "@/enums/imageType";
import NotesIconField from "@/components/NotesIconField.vue";
import HealingFactors from "@/components/Wounds/HealingFactors.vue";

import DatesMixin from "@/mixins/datesMixin";
import woundsMixin from "@/mixins/woundsMixin.js";
import checkDirty from "@/mixins/checkDirty.js";
import woundStatus from "@/enums/woundStatus";
import EnumDropDown from "@/components/EnumDropDown";
import SkinIntegrityEnum from "@/enums/skinIntegrity";
import lookups from "@/enums/lookups.js";
import PermissionsEnum from "@/enums/permissions";

export default {
  mixins: [woundsMixin, DatesMixin, checkDirty],
  components: {
    XyeaFormEdit,
    SpeechToText,
    BodyMap,
    LookupSelect,
    YesNoPicker,
    AttachmentsCarousel,
    EnumDropDown,
    NotesIconField,
    HealingFactors
  },
  props: ["wound"],
  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      healed: this.wound.status === woundStatus.Enum.Healed,
      attachmentType: Attachments.Type.WoundImage,
      skinIntegrity: SkinIntegrityEnum,
      lookupEnum: lookups.Enum
    };
  },
  computed: {
    woundsReadonly() {
      return !!this.form.woundId && !this.$store.getters.hasPermission(PermissionsEnum.WoundUpdate);
    },
    thisWound() {
      // array, just the one wound
      return [this.form];
    },
    locationRules() {
      return { bodyLocationRequired: [this.form.bodyPartId] };
    },
    healingFactorRules() {
      return { healingFactorRequired: [this.form.healingFactors.length] };
    },
    excludeList() {
      return this.form.healingFactors.map((h) => h.healingFactorId);
    },
    localisedHealingFactors() {
      return this.form.healingFactors.filter((h) => h.factorType === woundStatus.HealingFactorType.Localised);
    },
    systemicHealingFactors() {
      return this.form.healingFactors.filter((h) => h.factorType === woundStatus.HealingFactorType.Systemic);
    },
    showGrade() {
      return woundStatus.Ulcers.includes(this.form.woundTypeId);
    }
  },
  watch: {
    healed(v) {
      this.form.status = v ? woundStatus.Enum.Healed : woundStatus.Enum.Current;
    }
  },
  methods: {
    getClearFormObject() {
      return this.newWound();
    },
    refresh() {
      this.$emit("refresh");
    },
    updateWoundLocation(coord) {
      this.form.posX = coord.x;
      this.form.posY = coord.y;
      this.form.bodyPartId = coord.locationId;
    },
    addHealingFactor(hfType) {
      this.form.healingFactors.push(this.newHealingFactor(hfType));
    },
    deleteHealingFactor(hfId) {
      const i = this.form.healingFactors.findIndex((h) => h.hfId === hfId);
      if (i >= 0) this.form.healingFactors.splice(i, 1);
    },
    showNotes(healingFactor) {
      healingFactor.showNotes = true;
    },
    updateHealingFactorId(update) {
      update.h.healingFactorId = update.v;
    }
  },
  mounted() {
    this.form = { ...this.wound };
  }
};
</script>

<style scoped>
.minWidth {
  flex-grow: 0;
  width: 30px;
}
</style>
