<template>
  <div class="columns">
    <div class="column is-4-desktop">
      <BodyMap
        v-if="!editOpen"
        :wounds="filteredWounds"
        :gender="gender"
        @newdot="addWound"
        @selectWound="selectWound"
        :selectedWound="selectedWound"
        :id="id"
        :inDialog="inDialog"
        :zoomOnHover="zoomOnHover"
      />
    </div>
    <div class="column">
      <ContextTable
        :tableData="filteredWounds"
        sortField="categoryOrderId"
        v-model="selectedWound"
        :canEdit="!woundsReadonly"
        @contextDefaultClicked="editWound"
        @menu-clicked="editWound"
        :isLoading="isLoading"
      >
        <b-field v-if="!currentOnly" grouped class="centredDiv">
          <b-radio v-model="woundOption" name="woundOption" :native-value="woundStatusEnum.Enum.Current">
            {{ woundStatusEnum.Lookup[woundStatusEnum.Enum.Current] }}
          </b-radio>
          <b-radio v-model="woundOption" name="woundOption" :native-value="woundStatusEnum.Enum.Healed">
            {{ woundStatusEnum.Lookup[woundStatusEnum.Enum.Healed] }}
          </b-radio>
          <b-radio v-model="woundOption" name="woundOption" :native-value="woundStatusEnum.Enum.All">
            {{ woundStatusEnum.Lookup[woundStatusEnum.Enum.All] }}
          </b-radio>
        </b-field>

        <b-table-column label="Category" field="categoryOrderId" sortable v-slot="props">
          {{ props.row.woundCategory }}
        </b-table-column>
        <b-table-column label="Reported" field="reportedDate" sortable v-slot="props">
          {{ formatDateTimeRecent(props.row.reportedDate) }}
        </b-table-column>
        <b-table-column label="Location" field="bodyPartId" sortable v-slot="props">
          {{ woundLocationName(props.row.bodyPartId) }}
        </b-table-column>
        <b-table-column label="Skin Condition" field="primaryType" sortable v-slot="props">
          {{ skinLookup[props.row.primaryType] }}
        </b-table-column>
        <b-table-column label="Type" field="woundTypeId" sortable v-slot="props" :visible="!currentOnly && !woundsReadonly">
          {{ woundTypeName(props.row.woundTypeId) }}
        </b-table-column>
        <b-table-column label="Description" field="description" sortable v-slot="props" v-if="!currentOnly">
          {{ props.row.description }}
        </b-table-column>
        <b-table-column :visible="woundOption !== woundStatusEnum.Enum.Current" label="Healed" field="healed" sortable v-slot="props">
          {{ formatDateTimeRecent(props.row.healedDateLocal) }}
        </b-table-column>
      </ContextTable>
    </div>
  </div>
</template>

<script>
import BodyMap from "@/components/Wounds/BodyMap";
import ContextTable from "@/components/ContextTable";

import ResidentMixin from "@/mixins/residentMixin";
import woundsMixin from "@/mixins/woundsMixin.js";
import DatesMixin from "@/mixins/datesMixin";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";
import woundStatus from "@/enums/woundStatus";
import WoundDialog from "@/components/dialogs/WoundDialog.vue";
import SkinIntegrityEnum from "@/enums/skinIntegrity";

export default {
  mixins: [ResidentMixin, woundsMixin, DatesMixin, IconsMixin],
  components: { BodyMap, ContextTable },
  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean },
    currentOnly: { type: Boolean },
    inDialog: { type: Boolean },
    zoomOnHover: { type: Boolean, default: true }
  },
  data() {
    return {
      skinLookup: SkinIntegrityEnum.Lookup
    };
  },

  watch: {
    residentId: "refreshWounds"
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    woundsReadonly() {
      return !this.$store.getters.hasPermission(PermissionsEnum.WoundUpdate);
    },
    headerIcons() {
      const icons = [IconsMixin.headerIconRefresh()];
      if (this.hasPermission(PermissionsEnum.AssessmentAdd)) {
        icons.unshift(IconsMixin.headerIconAdd("Wound"));
      }
      return icons;
    },
    filteredWounds() {
      if (this.woundOption === woundStatus.Enum.All) return this.wounds;
      return this.wounds.filter((w) => w.status === this.woundOption);
    }
  },
  methods: {
    headerClicked(iconName) {
      switch (iconName) {
        case "add":
          this.addWound();
          break;
        case "refresh":
          this.refreshWounds();
          break;
      }
    },

    editWound() {
      this.editOpen = true; // prevent two wounds maps from showing
      // modal open would not work when called from mixin
      this.$buefy.modal.open({
        parent: this,
        component: WoundDialog,
        hasModalCard: true,
        width: "75vw",
        props: { wound: this.selectedWound, residentId: this.residentId },
        events: { refresh: this.refreshWounds, close: this.closedWoundDialog, woundAdded: this.woundAdded }
      });
    },
    woundAdded(newWound) {
      this.wounds.push(newWound);
      this.selectWound(newWound.woundId);
      this.$emit("woundAdded", newWound.woundId);
    }
  }
};
</script>
