import lookups from "@/enums/lookups.js";
import woundStatus from "@/enums/woundStatus";
import PermissionsEnum from "@/enums/permissions";

export default {
  props: {
    selectWoundIds: { type: Array }
  },
  data() {
    return {
      lookupWoundType: lookups.Enum.WoundType,
      isLoading: false,
      wounds: [],
      selectedWound: {},
      editOpen: false,
      woundStatusEnum: woundStatus,
      woundOption: woundStatus.Enum.Current,
      newHfId: 0
    };
  },
  computed: {
    updateAdvanced() {
      return this.$store.getters.hasPermission(PermissionsEnum.WoundUpdate);
    },
    gender() {
      return this.$store.getters["resident/residentMedicalGender"];
    },
    woundTypes() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: this.lookupWoundType, gender: this.gender });
      if ((!values || !values.length) && !this.fetchingWoundTypes) {
        this.getWoundTypes();
      }
      return values;
    },
    woundLocations() {
      var values = this.$store.getters["lookups/getLookups"]({ lookupType: lookups.Enum.WoundLocation, gender: this.gender });
      if ((!values || !values.length) && !this.fetchingWoundLocations) {
        this.getWoundLocations();
      }
      return values;
    }
  },
  methods: {
    newWound(coord) {
      return {
        posX: coord?.x,
        posY: coord?.y,
        woundId: 0,
        residentId: this.$store.getters["resident/residentId"],
        organisationId: this.$store.getters.orgId,
        description: null,
        notes: null,
        onSite: null,
        status: woundStatus.Enum.Current,
        primaryType: null,
        woundTypeId: null,
        bodyPartId: coord?.locationId,
        photos: [],
        reportedDate: null,
        healedDateLocal: null,
        underlyingCause: null,
        healingFactors: [],
        anyHealingFactors: null,
        noHealingFactorNotes: null
      };
    },
    newHealingFactor(hfType) {
      return {
        hfId: this.newHfId--,
        healingFactorId: null,
        notes: null,
        showNotes: false,
        factorType: hfType
      };
    },
    async getWoundTypes() {
      this.fetchingWoundTypes = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: this.lookupWoundType });
      this.fetchingWoundTypes = false;
    },
    async getWoundLocations() {
      this.fetchingWoundLocations = true;
      await this.$store.dispatch("lookups/getLookups", { lookupType: lookups.Enum.WoundLocation });
      this.fetchingWoundLocations = false;
    },
    woundLocationName(locationId) {
      return this.woundLocations.find(w => w.lookupId === locationId)?.name;
    },
    woundTypeName(woundTypeId) {
      return this.woundTypes.find(w => w.lookupId === woundTypeId)?.name;
    },
    refreshWounds() {
      if (this.isLoading || !this.residentId) return;
      this.isLoading = true;
      this.$http
        .get("wounds/" + this.residentId)
        .then(r => {
          this.wounds = r.data;
          // Only selecting first one for now, Todo: Select all wounds
          if (this.selectWoundIds?.length) this.selectWound(this.selectWoundIds[0]);
          this.$emit("gotNumWounds", this.wounds.length);
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading wounds"))
        .finally(() => (this.isLoading = false));
    },

    selectWound(woundId) {
      const wound = this.wounds.find(w => w.woundId === woundId);
      if (wound) this.selectedWound = wound;
    },
    addWound(coord) {
      if (this.disabled) return;
      const newWound = this.newWound(coord);
      this.selectedWound = newWound;
      this.$nextTick(this.editWound);
    },

    closedWoundDialog() {
      this.editOpen = false;
    }
  }
};
