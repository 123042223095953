<template>
  <section class="section is-main-section">
    <CardComponent title="Wounds" icon="wound" :header-icons="headerIcons" @header-icons-click="headerClicked">
      <WoundsList ref="woundList" id="wounds" :zoomOnHover="false" />
    </CardComponent>
  </section>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import WoundsList from "@/components/Wounds/WoundsList2";
// import PinchZoom from "pinch-zoom-js";
import Hammer from "hammerjs";

import ResidentMixin from "@/mixins/residentMixin";
import DatesMixin from "@/mixins/datesMixin";
import { mapGetters } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import IconsMixin from "@/mixins/iconsMixin.js";

export default {
  mixins: [ResidentMixin, DatesMixin, IconsMixin],
  components: { CardComponent, WoundsList },

  computed: {
    ...mapGetters(["hasPermission"]),
    headerIcons() {
      const icons = [IconsMixin.headerIconRefresh()];
      if (this.hasPermission(PermissionsEnum.WoundAdd)) {
        icons.unshift(IconsMixin.headerIconAdd("Wound"));
      }
      return icons;
    },
    bodyMap() {
      return require("@/assets/male_3d.png");
    }
  },
  methods: {
    headerClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$refs.woundList.addWound();
          break;
        case "refresh":
          this.$refs.woundList.refreshWounds();
          break;
      }
    }
  },
  mounted() {
    const el = document.querySelector("#pinchImage");
    // this.pz = new PinchZoom(el, {
    //   onZoomStart: () => console.log("onZoomStart"),
    //   onZoomEnd: () => console.log("onZoomEnd"),
    //   onZoomUpdate: () => console.log("onZoomUpdate")
    // });

    var mc = new Hammer(el, {});
    mc.get("pinch").set({ enable: true });
    mc.on("panleft panright tap press pinch pinchstart pinchmove pinchend pinchin pinchout", function (e) {
      // console.log(e.type, e);
      if (e.type === "pinchout") console.log(e.type, e.scale, e.center, e);
    });
  }
};
</script>

<style scoped>
.pinchContainer {
  min-height: 300px;
}
</style>
